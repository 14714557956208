/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.settingDrawerFeildStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
